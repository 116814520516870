import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaTelegram, FaCode } from 'react-icons/fa';
import './Navbar.css';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
  
    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleTalkToUs = () => {
        window.open('https://t.me/+ioVcWYVASiM5ZTJl', '_blank');
    };

    const handleCPHelper = () => {
        window.open('https://cphelper.online', '_blank');
    };

   

    return (
        <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <span className="logo-text">OA </span>
                    <span className="logo-helper">Helper</span>
                </Link>
                <div className="nav-links">
                   
                    <a href="#" onClick={handleCPHelper} className="nav-link">
                        <FaCode />
                        <span>CP Helper</span>
                    </a>
                    <a href="#" onClick={handleTalkToUs} className="nav-link">
                        <FaTelegram />
                        <span>Join Community</span>
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;