import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useLocation, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { FaExternalLinkAlt, FaLock, FaShare } from 'react-icons/fa';
import './QuestionView.css';

const QuestionView = () => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('Python3');
    const { id } = useParams();
    const location = useLocation();
    const questionData = location.state?.questionData;
    const [isPopupVisible, setIsPopupVisible] = useState(false);

    // Code templates for different languages
    const codeTemplates = {
        'Python3': `class Solution:
    def twoSum(self, nums: List[int], target: int) -> List[int]:
        seen = {}
        for i, value in enumerate(nums):
            remaining = target - value
            
            if remaining in seen:
                return [seen[remaining], i]
                
            seen[value] = i`,

        'JavaScript': `/**
 * @param {number[]} nums
 * @param {number} target
 * @return {number[]}
 */
var twoSum = function(nums, target) {
    const seen = new Map();
    
    for (let i = 0; i < nums.length; i++) {
        const remaining = target - nums[i];
        
        if (seen.has(remaining)) {
            return [seen.get(remaining), i];
        }
        
        seen.set(nums[i], i);
    }
};`,

        'Java': `class Solution {
    public int[] twoSum(int[] nums, int target) {
        Map<Integer, Integer> seen = new HashMap<>();
        
        for (int i = 0; i < nums.length; i++) {
            int remaining = target - nums[i];
            
            if (seen.containsKey(remaining)) {
                return new int[] { seen.get(remaining), i };
            }
            
            seen.put(nums[i], i);
        }
        
        return new int[] {};
    }
}`,

        'C++': `class Solution {
public:
    vector<int> twoSum(vector<int>& nums, int target) {
        unordered_map<int, int> seen;
        
        for (int i = 0; i < nums.size(); i++) {
            int remaining = target - nums[i];
            
            if (seen.find(remaining) != seen.end()) {
                return {seen[remaining], i};
            }
            
            seen[nums[i]] = i;
        }
        
        return {};
    }
};`
    };

    useEffect(() => {
        const fetchQuestion = async () => {
            if (questionData) {
                console.log('Using passed question data:', questionData);
                setQuestion(questionData);
                setLoading(false);
                return;
            }

            try {
                console.log('Fetching question ID:', id);
                
                const response = await axios.get(`/question.php?id=${id}`);
                
                console.log('API Response:', response.data);

                if (response.data.status === 'success' && response.data.data) {
                    const questionWithCompany = {
                        ...response.data.data,
                        company_name: response.data.data.company_name || 'Unknown Company'
                    };
                    console.log('Setting question with company:', questionWithCompany);
                    setQuestion(questionWithCompany);
                } else {
                    setError(response.data.message || 'Failed to load question');
                }
            } catch (error) {
                console.error('Error fetching question:', error);
                setError(error.response?.data?.message || error.message || 'Failed to fetch question');
            } finally {
                setLoading(false);
            }
        };

        fetchQuestion();
    }, [id, questionData]);

    useEffect(() => {
        console.log('Current question state:', question);
    }, [question]);

    console.log('Current state:', { loading, error, question });

    // Handle sharing functionality
    const handleShare = async () => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: question?.title,
                    text: `Check out this coding problem: ${question?.title}`,
                    url: window.location.href
                });
            } else {
                // Fallback: Copy to clipboard
                await navigator.clipboard.writeText(window.location.href);
                alert('Link copied to clipboard!');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    const handleUnlockClick = () => {
        setIsPopupVisible(true);
    };

    const closePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <div className="leetcode-container">
            <div className="leetcode-header">
                <div className="header-left">
                    <Link to="/" className="back-button">
                        <span>←</span> Problem List
                    </Link>
                </div>
                <div className="header-right">
                    <button className="share-button" onClick={handleShare}>
                        <FaShare /> Share
                    </button>
                    {question?.google_doc_link && (
                        <a 
                            href={question.google_doc_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="original-link"
                        >
                            <FaExternalLinkAlt /> Original Problem
                        </a>
                    )}
                </div>
            </div>

            <div className="leetcode-content">
                <div className="description-panel">
                    {loading ? (
                        <div className="loading">
                            <div className="loading-spinner"></div>
                        </div>
                    ) : error ? (
                        <div className="error">
                            <p>{error}</p>
                            <Link to="/" className="back-link">← Back to Questions</Link>
                        </div>
                    ) : question && (
                        <>
                            <div className="description-header">
                                <div className="title-section">
                                    {question.company_name && (
                                        <h1 className="company-name">
                                            {question.company_name}
                                        </h1>
                                    )}
                                    <div className="header-buttons">
                                        <button className="share-button" onClick={handleShare}>
                                            <FaShare /> Share   This Problem
                                        </button>
                                        {question?.google_doc_link && (
                                            <a 
                                                href={question.google_doc_link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="original-link"
                                            >
                                                <FaExternalLinkAlt /> Original Problem Statement
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="description-content">
                                <div 
                                    className="problem-text"
                                    dangerouslySetInnerHTML={{ 
                                        __html: DOMPurify.sanitize(question.problem_statement)
                                    }} 
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className="solution-panel">
                    <div className="solution-header">
                        <div className="language-selector">
                            <select 
                                value={selectedLanguage}
                                onChange={(e) => setSelectedLanguage(e.target.value)}
                            >
                                <option>Python3</option>
                                <option>JavaScript</option>
                                <option>Java</option>
                                <option>C++</option>
                            </select>
                        </div>
                        <button className="unlock-button" onClick={handleUnlockClick}>
                            <FaLock /> Unlock Solution
                        </button>
                    </div>
                    <div className="code-editor">
                        <div className="blur-overlay">
                            <FaLock className="lock-icon" />
                            <span>Premium Solution</span>
                            <span className="unlock-text">Unlock to view the solution</span>
                        </div>
                        <pre>
                            <code className="code-content">
                                {codeTemplates[selectedLanguage]}
                            </code>
                        </pre>
                    </div>
                    
                    {/* Test Cases Section */}
                    <div className="testcases-wrapper">
                        <div className="testcase-header">
                            <div className="testcase-tabs">
                                <div className="testcase-tab active">Case 1</div>
                                <div className="testcase-tab">Case 2</div>
                                <div className="testcase-tab">Case 3</div>
                            </div>
                            <span className="testcase-result success">
                                <span className="dot"></span>
                                Accepted
                            </span>
                        </div>
                        <div className="testcase-content">
                            <div className="blur-overlay">
                                <FaLock className="lock-icon" />
                                <span>Premium Test Cases</span>
                                <span className="unlock-text">Unlock to view all test cases</span>
                            </div>
                            <div className="testcase-details">
                                <div className="testcase-item">
                                    <div className="testcase-label">Input:</div>
                                    <div className="testcase-value">nums = [2,7,11,15], target = 9</div>
                                </div>
                                <div className="testcase-item">
                                    <div className="testcase-label">Output:</div>
                                    <div className="testcase-value">[0,1]</div>
                                </div>
                                <div className="testcase-item">
                                    <div className="testcase-label">Expected:</div>
                                    <div className="testcase-value">[0,1]</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-overlay" onClick={closePopup}>
                    <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                        <h2>Premium Solution</h2>
                        <p>This solution is exclusive to premium members. Your support helps us maintain and improve the platform.</p>
                        <div className="popup-buttons">
                            <a 
                                href={`https://t.me/cphelper9?start=question_${encodeURIComponent(question?.title || '')}_${encodeURIComponent(question?.company_name || '')}`}
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="proceed-button"
                            >
                                Proceed to Solution
                            </a>
                            <button onClick={closePopup} className="close-button">Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionView;