import React, { useState, useEffect, lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

import './App.css';
import AdminDashboard from './components/Admin/AdminDashboard';
import CompanyOA from './components/CompanyOA';
import ResumeReview from './components/ResumeReview';

import SDEsheet from './components/SDEsheet.js';
import QuestionView from './components/QuestionView';
// Lazy load the Snowfall component
const Snowfall = lazy(() => import('react-snowfall'));

function App() {
  const [showSnow, setShowSnow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Initialize Tawk.to
    var Tawk_API = window.Tawk_API || {};
    var Tawk_LoadStart = new Date();
    
    const s1 = document.createElement("script");
    const s0 = document.getElementsByTagName("script")[0];
    
    s1.async = true;
    s1.src = 'https://embed.tawk.to/66fee7f2256fb1049b1c86fe/1i99rk478';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    
    s0.parentNode.insertBefore(s1, s0);

    // Delay the snowfall animation
    const timer = setTimeout(() => {
      setShowSnow(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="app">
      {showSnow && (
        <Suspense fallback={<div>Loading...</div>}>
          <Snowfall
            color="rgba(255, 255, 255, 0.5)"
            snowflakeCount={30}
            radius={[1, 5]}
            speed={[0.5, 3]}
            wind={[-0.5, 2]}
          />
        </Suspense>
      )}

      <Navbar toggleMenu={toggleMenu} />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<CompanyOA />} />
          <Route path="/admin" element={<AdminDashboard />} />
         
          <Route path="/sde-sheet" element={<SDEsheet />} />
          <Route path="/resume-review" element={<ResumeReview />} />
          <Route path="/question/:id" element={<QuestionView />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
