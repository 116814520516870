import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    FaLock, 
    FaExternalLinkAlt, 
    FaSearch,
    FaTimes,
    FaChevronDown,
    FaChevronUp
} from 'react-icons/fa';
import DOMPurify from 'dompurify';
import '../styles/SDEsheet.css';

const SDESheet = () => {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedQuestion, setExpandedQuestion] = useState(null);

    useEffect(() => {
        fetchQuestions();
    }, []);

    const fetchQuestions = async () => {
        try {
            const response = await axios.get('https://oahelper.in/company_oa.php?action=sde_sheet');
            if (response.data.status === 'success') {
                setQuestions(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setLoading(false);
        }
    };

    const filteredQuestions = questions.filter(question =>
        question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.company_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="sde-sheet-container">
            <div className="sde-sheet-header">
                <h1>SDE Interview Preparation Sheet</h1>
                <p>Collection of latest interview questions from top companies</p>
                
                <div className="sde-search-box">
                    <FaSearch className="search-icon" />
                    <input
                        type="text"
                        placeholder="Search questions or companies..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                        <button 
                            className="clear-search"
                            onClick={() => setSearchTerm('')}
                        >
                            <FaTimes />
                        </button>
                    )}
                </div>
            </div>

            {loading ? (
                <div className="sde-loading">Loading questions...</div>
            ) : (
                <div className="sde-questions-list">
                    {filteredQuestions.map((question, index) => (
                        <div key={question.id} className="sde-question-card">
                            <div className="sde-question-header">
                                <span className="question-number">{index + 1}</span>
                                <div className="question-main-info">
                                    <h3>{question.title}</h3>
                                    <span className="company-name">{question.company_name}</span>
                                </div>
                                <div className="question-actions">
                                    {question.google_doc_link && (
                                        <a 
                                            href={question.google_doc_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="original-link"
                                        >
                                            <FaExternalLinkAlt /> Original
                                        </a>
                                    )}
                                    <button 
                                        className="expand-btn"
                                        onClick={() => setExpandedQuestion(
                                            expandedQuestion === question.id ? null : question.id
                                        )}
                                    >
                                        {expandedQuestion === question.id ? 
                                            <FaChevronUp /> : <FaChevronDown />
                                        }
                                    </button>
                                </div>
                            </div>

                            {expandedQuestion === question.id && (
                                <div className="sde-question-content">
                                    <div 
                                        className="problem-statement"
                                        dangerouslySetInnerHTML={{ 
                                            __html: DOMPurify.sanitize(question.problem_statement)
                                        }} 
                                    />
                                    <div className="solution-preview">
                                        <div className="solution-blur">
                                            <FaLock />
                                            <span>View Solution</span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SDESheet;