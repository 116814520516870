import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import axios from 'axios';
import { 
  FaChevronDown, 
  FaChevronUp, 
  FaBuilding, 
  FaSearch, 
  FaTimes, 
  FaLock, 
  FaEnvelope, 
  FaExternalLinkAlt,
  FaAmazon,
  FaGoogle,
  FaMicrosoft,
  FaApple,
  FaFacebook,
  FaLinkedin,
  FaUber,
  FaTwitter,
  FaStripe,
  FaSnapchat,
  FaHeadset,
  FaTelegram,
  FaGithub,
  FaAngleDown,
  FaInfoCircle,
  FaCheck,
  FaShare,
  FaEye,
  FaCrown,
  FaArrowRight
} from 'react-icons/fa';
import { SiNetflix, SiTesla, SiOracle, SiAdobe, SiCplusplus } from 'react-icons/si';
import { GiBatMask } from 'react-icons/gi'; // for Batman logo
import './CompanyOA.css';
import { useNavigate, Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import '../styles/SearchMinimal.css';
import '../styles/GlassTable.css';
import ResumeReview from './ResumeReview';

// Add this helper function at the top of your component
const formatDate = (date) => {
    const d = new Date(date);
    const isMobile = window.innerWidth <= 768;
    
    if (isMobile) {
        // Shorter format for mobile: "Dec 25"
        return d.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
        });
    }
    
    // Full format for desktop: "Dec 25, 2023"
    return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });
};

// Move SearchBar outside the main component
const SearchBar = memo(({ searchTerm, setSearchTerm, searchType, setSearchType }) => {
    const [isTyping, setIsTyping] = useState(false);
    let typingTimer;

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsTyping(true);
        
        // Clear the previous timer
        clearTimeout(typingTimer);
        
        // Set a new timer
        typingTimer = setTimeout(() => {
            setIsTyping(false);
        }, 500); // Animation will stop 500ms after user stops typing
    };

    // Cleanup timer on unmount
    useEffect(() => {
        return () => {
            if (typingTimer) {
                clearTimeout(typingTimer);
            }
        };
    }, []);

    return (
        <div className="search-hero">
            <div className="search-header">
                <h2 className="search-tagline">
                    <div className="tagline-highlight">Latest OA Questions</div>
                    <div className="tagline-sub">From Top Tech Companies • Updated Daily • Free Access</div>
                </h2>
            </div>
            <div className="search-box">
                <div className={`search-icon ${isTyping ? 'typing' : ''}`}>
                    <FaSearch />
                </div>
                <input
                    type="text"
                    className="search-input"
                    placeholder={
                        searchType === 'companies' 
                            ? 'Search companies...' 
                            : searchType === 'questions'
                                ? 'Search questions...'
                                : 'Search companies & questions...'
                    }
                    value={searchTerm}
                    onChange={handleInputChange}
                />
                {searchTerm && (
                    <button 
                        className="search-clear"
                        onClick={() => {
                            setSearchTerm('');
                            setIsTyping(false);
                        }}
                    >
                        <FaTimes />
                    </button>
                )}
            </div>
            <div className="search-type-selector">
                {['all', 'companies', 'questions'].map((type) => (
                    <button 
                        key={type}
                        className={`search-type-btn ${searchType === type ? 'active' : ''}`}
                        onClick={() => setSearchType(type)}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                ))}
            </div>
        </div>
    );
});

// Move PaymentPopup outside the main component
const PaymentPopup = memo(({ 
    showPaymentPopup, 
    setShowPaymentPopup, 
    solutionEmail, 
    setSolutionEmail, 
    handleSolutionSubmit, 
    isSubmitting,
    selectedQuestion 
}) => (
    <div className="sleek-popup-overlay" onClick={() => setShowPaymentPopup(false)}>
        <div className="sleek-popup-content" onClick={e => e.stopPropagation()}>
            <div className="pricing-cards-container">
                {/* Single Solution Card */}
                <div className="pricing-card">
                    <div className="card-header">
                        <div className="card-icon">
                            <FaLock />
                        </div>
                        <div className="card-title">Single Solution</div>
                    </div>
                    
                    <div className="price-block">
                        <div className="price">
                            <span className="rupee">₹</span>
                            <span className="amount">20</span>
                        </div>
                        <div className="period">one-time</div>
                    </div>

                    <div className="features-list">
                        <div className="feature-item">
                            <FaCheck /> Access to this solution
                        </div>
                        <div className="feature-item">
                            <FaCheck /> Detailed explanation
                        </div>
                        <div className="feature-item">
                            <FaCheck /> Code implementation
                        </div>
                    </div>

                    <button 
                        className="buy-button"
                        onClick={() => handleSolutionSubmit(selectedQuestion, 'single')}
                        disabled={isSubmitting}
                    >
                        BUY SINGLE SOLUTION
                    </button>
                </div>

                {/* Premium Card */}
                <div className="pricing-card premium">
                    <div className="best-value-badge">BEST VALUE</div>
                    <div className="card-header">
                        <div className="card-icon">
                            <FaCrown />
                        </div>
                        <div className="card-title">Premium Access</div>
                    </div>
                    
                    <div className="price-block">
                        <div className="price">
                            <span className="rupee">₹</span>
                            <span className="amount">299</span>
                        </div>
                        <div className="period">for 6 months</div>
                    </div>

                    <div className="features-list">
                        <div className="feature-item">
                            <FaCheck /> Unlimited solutions
                        </div>
                        <div className="feature-item">
                            <FaCheck /> Premium support
                        </div>
                        <div className="feature-item">
                            <FaCheck /> Early access
                        </div>
                        <div className="feature-item">
                            <FaCheck /> 6 months validity
                        </div>
                    </div>

                    <button 
                        className="buy-button premium"
                        onClick={() => handleSolutionSubmit(selectedQuestion, 'premium')}
                        disabled={isSubmitting}
                    >
                        GET PREMIUM ACCESS
                    </button>
                </div>
            </div>

            <div className="email-input-container">
                <input
                    type="email"
                    className="email-input"
                    placeholder="Enter your email"
                    value={solutionEmail}
                    onChange={(e) => setSolutionEmail(e.target.value)}
                />
            </div>
        </div>
    </div>
));

const SAMPLE_CPP_CODE = `#include <bits/stdc++.h>
using namespace std;

class Solution {
public:
    vector<int> solve(vector<int>& nums) {
        // Implementation hidden
        vector<int> result;
        int n = nums.size();
        
        // More code here...
        for(int i = 0; i < n; i++) {
            // Complex logic here...
        }
        
        return result;
    }
};`;

// Updated footer with detailed legal notice
const Footer = () => (
    <footer className="sleek-footer">
        <div className="footer-content">
            <div className="footer-links">
                <a href="mailto:support@oahelper.com" className="footer-link">
                    <FaEnvelope /> support@oahelper.com
                </a>
                <span className="footer-divider"></span>
                <a href="https://t.me/oahelper" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <FaTelegram /> Telegram support (Fast)
                </a>
            </div>
            <div className="footer-legal">
                <p className="footer-notice">
                    All content, including questions, solutions, and images, is protected by copyright law.
                </p>
                <p className="footer-warning">
                    Using content from this site for commercial purposes without authorization will result in legal action.
                </p>
            </div>
        </div>
    </footer>
);

const CompanyOA = () => {
    const [companies, setCompanies] = useState([]);
    const [expandedCompany, setExpandedCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedSolutionButton, setExpandedSolutionButton] = useState(null);
    const [solutionEmail, setSolutionEmail] = useState('');
    const [expandedProblem, setExpandedProblem] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editingQuestion, setEditingQuestion] = useState(null);
    const navigate = useNavigate();
    const [isSearching, setIsSearching] = useState(false);
    const [globalSearchResults, setGlobalSearchResults] = useState(null);
    const searchDebounceRef = useRef(null);
    const [showPaymentPopup, setShowPaymentPopup] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [searchType, setSearchType] = useState('all');
    const [scrollToId, setScrollToId] = useState(null);
    const questionRefs = useRef({});
    const [scrollProgress, setScrollProgress] = useState(0);
    const [resumes, setResumes] = useState([]);
    const [showResumeReview, setShowResumeReview] = useState(false);

    useEffect(() => {
        if (scrollToId && questionRefs.current[scrollToId]) {
            questionRefs.current[scrollToId].scrollIntoView({ 
                behavior: 'smooth',
                block: 'center'
            });
            questionRefs.current[scrollToId].classList.add('question-highlight');
            setTimeout(() => {
                questionRefs.current[scrollToId].classList.remove('question-highlight');
            }, 2000);
        }
    }, [scrollToId]);

    useEffect(() => {
        const loadQuestionFromUrl = async () => {
            const hash = window.location.hash;
            if (hash && companies.length > 0) {
                const questionId = parseInt(hash.replace('#question-', ''));
                
                // Find the company that contains this question
                const company = companies.find(c => 
                    c.questions?.some(q => q.id === questionId)
                );
                
                if (company) {
                    setExpandedCompany(company.id);
                    setExpandedProblem(questionId);
                    setScrollToId(questionId);
                }
            }
        };

        loadQuestionFromUrl();
    }, [companies]); // Depend on companies array

    const fetchCompanies = useCallback(async () => {
        try {
            setError(null);
            
            const response = await axios.get(`/company_oa.php`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                withCredentials: false,
                timeout: 10000
            });

            if (response.data && response.data.status === 'success') {
                setCompanies(response.data.data);
            } else {
                throw new Error('Invalid data format received');
            }
        } catch (err) {
            console.error('Error fetching companies:', err);
            setError(err.message || 'Failed to load companies. Please try again later.');
            setCompanies([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    const toggleCompany = (companyId) => {
        setExpandedCompany(expandedCompany === companyId ? null : companyId);
    };

    const filterCompanies = useCallback(() => {
        if (!searchTerm.trim()) {
            return companies;
        }

        const searchLower = searchTerm.toLowerCase();
        
        return companies.filter(company => {
            // Check if company name matches
            const companyMatches = company.name.toLowerCase().includes(searchLower);
            
            // Check if any questions match
            const questionsMatch = company.questions?.some(question =>
                question.title.toLowerCase().includes(searchLower)
            );

            return companyMatches || questionsMatch;
        }).map(company => {
            // If searching for a question, only show matching questions
            if (!company.name.toLowerCase().includes(searchLower)) {
                return {
                    ...company,
                    questions: company.questions.filter(question =>
                        question.title.toLowerCase().includes(searchLower)
                    )
                };
            }
            return company;
        });
    }, [companies, searchTerm]);

    const handleSolutionClick = (questionId, e) => {
        e.stopPropagation();
        setSelectedQuestion(questionId);
        setShowPaymentPopup(true);
    };

    const handleSolutionSubmit = async (questionId) => {
        setIsSubmitting(true);
        
        try {
            if (!solutionEmail) {
                throw new Error('Please enter your email address');
            }

            // Get the current question details from the companies state
            const currentCompany = companies.find(company => 
                company.questions.some(q => q.id === questionId)
            );
            const currentQuestion = currentCompany?.questions.find(q => q.id === questionId);

            if (!currentQuestion) {
                throw new Error('Question details not found');
            }

            const response = await axios.post(`/company_oa.php`, {
                action: 'send_solution',
                email: solutionEmail,
                problemName: currentQuestion.title,
                companyName: currentCompany.name
            });
            
            if (response.data.status === 'success') {
                setShowPaymentPopup(false);
                setShowSuccessPopup(true); // Show success popup
                setTimeout(() => setShowSuccessPopup(false), 3000); // Hide after 3 seconds
            } else {
                throw new Error(response.data.message || 'Failed to send email');
            }
        } catch (error) {
            console.error('Error:', error);
            alert(error.message || 'Failed to send email. Please try again later.');
        } finally {
            setIsSubmitting(false);
            setSelectedQuestion(null);
            setSolutionEmail('');
        }
    };

    const getCompanyIcon = (companyName) => {
        const name = companyName.toLowerCase();
        
        const iconMap = {
            'amazon': FaAmazon,
            'google': FaGoogle,
            'microsoft': FaMicrosoft,
            'apple': FaApple,
            'meta': FaFacebook,
            'facebook': FaFacebook,
            'linkedin': FaLinkedin,
            'uber': FaUber,
            'twitter': FaTwitter,
            'netflix': SiNetflix,
            'tesla': SiTesla,
            'oracle': SiOracle,
            'adobe': SiAdobe,
            'stripe': FaStripe,
            'snapchat': FaSnapchat
        };

        for (const [keyword, Icon] of Object.entries(iconMap)) {
            if (name.includes(keyword)) {
                return Icon;
            }
        }

        return FaBuilding;
    };

    const getTimeAgo = (date) => {
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 1) return '1 day ago';
        if (diffDays < 30) return `${diffDays} days ago`;
        if (diffDays < 365) {
            const months = Math.floor(diffDays / 30);
            return `${months} ${months === 1 ? 'month' : 'months'} ago`;
        }
        const years = Math.floor(diffDays / 365);
        return `${years} ${years === 1 ? 'year' : 'years'} ago`;
    };

    const handleProblemClick = (problem) => {
        navigate(`/problem/${problem.id}`, { 
            state: { 
                problem: {
                    ...problem,
                    company: problem.company_name || 'Unknown Company',
                    difficulty: problem.difficulty || 'Medium',
                    description: problem.description || problem.content || 'Description not available.',
                    examples: problem.examples || []
                } 
            }
        });
    };

    // eslint-disable-next-line no-unused-vars
    const handleEditQuestion = async (questionId, newData) => {
        try {
            // Add your edit question logic here
            // await axios.put(`/api/questions/${questionId}`, newData);
            // fetchCompanies(); // Refresh the data
        } catch (error) {
            console.error('Error editing question:', error);
        }
    };

    // Add this helper function to format the problem statement
    const formatProblemStatement = (rawText) => {
        if (!rawText) return '';

        // First, convert the raw text to proper HTML structure
        const processedText = rawText
            .replace(/\\n\\n/g, '</div><div class="section">') // Double newlines create new sections
            .replace(/\\n/g, '<br>') // Single newlines become line breaks
            .replace(/`([^`]+)`/g, '<code>$1</code>'); // Convert backticks to code tags

        // Create sections for different parts of the problem
        let html = `
            <div class="problem-container">
                <div class="problem-description">
                    ${processedText}
                </div>
            </div>
        `;

        // Process special sections
        html = html
            .replace('Input Format', '<h3 class="section-title">Input Format</h3>')
            .replace('Output Format', '<h3 class="section-title">Output Format</h3>')
            .replace('Constraints', '<h3 class="section-title">Constraints</h3>')
            .replace('Examples', '<h3 class="section-title">Examples</h3>')
            .replace('Note', '<h3 class="section-title">Note</h3>');

        return html;
    };

    const handleSearch = useCallback(async (searchTerm) => {
        setSearchTerm(searchTerm);
        
        // Clear previous timeout
        if (searchDebounceRef.current) {
            clearTimeout(searchDebounceRef.current);
        }

        if (!searchTerm.trim()) {
            setGlobalSearchResults(null);
            return;
        }

        setIsSearching(true);

        // Debounce the search
        searchDebounceRef.current = setTimeout(async () => {
            try {
                const response = await axios.get(`/company_oa.php`, {
                    params: {
                        action: 'search',
                        term: searchTerm
                    }
                });

                if (response.data.status === 'success') {
                    setGlobalSearchResults(response.data.data);
                }
            } catch (error) {
                console.error('Search error:', error);
            } finally {
                setIsSearching(false);
            }
        }, 300); // 300ms debounce
    }, []);

    const SuccessPopup = () => {
        return (
            <div className="sleek-popup-overlay success-overlay">
                <div className="sleek-popup-content success-content">
                    <div className="success-icon">
                        <FaEnvelope />
                    </div>
                    <h3>Check Your Email!</h3>
                    <p>We've sent payment instructions to your email.</p>
                    <button 
                        className="sleek-done-btn"
                        onClick={() => setShowSuccessPopup(false)}
                    >
                        Done
                    </button>
                </div>
            </div>
        );
    };

    // Memoize handlers
    const handleSearchChange = useCallback((value) => {
        setSearchTerm(value);
    }, []);

    const handleSolutionEmailChange = useCallback((value) => {
        setSolutionEmail(value);
    }, []);

    const LoadingAnimation = () => (
        <div className="sleek-loading-container">
            <div className="sleek-loading-content">
                <div className="sleek-loading-spinner" />
                <div className="sleek-loading-text">
                    Loading Data
                    <div className="sleek-loading-dots">
                        <div className="sleek-loading-dot"></div>
                        <div className="sleek-loading-dot"></div>
                        <div className="sleek-loading-dot"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    // Function to find company ID by question ID
    const findCompanyByQuestionId = (questionId) => {
        return companies.find(company => 
            company.questions.some(question => question.id === parseInt(questionId))
        )?.id;
    };

    // Add scroll effect when expandedProblem changes
    useEffect(() => {
        if (scrollToId && questionRefs.current[scrollToId]) {
            setTimeout(() => {
                questionRefs.current[scrollToId]?.scrollIntoView({ 
                    behavior: 'smooth',
                    block: 'center'
                });
                questionRefs.current[scrollToId]?.classList.add('question-highlight');
                setTimeout(() => {
                    questionRefs.current[scrollToId]?.classList.remove('question-highlight');
                }, 2000);
            }, 300); // Increased delay to ensure content is rendered
        }
    }, [scrollToId, expandedCompany, expandedProblem]);

    // Add this useEffect for scroll tracking
    useEffect(() => {
        const handleScroll = () => {
            const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
            const progress = (window.pageYOffset / totalHeight) * 100;
            setScrollProgress(progress);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const fetchResumes = async () => {
        try {
            const response = await axios.get('/company_oa.php', {
                params: {
                    action: 'get_all_resumes'
                }
            });
            if (response.data.status === 'success') {
                setResumes(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching resumes:', error);
            // Handle error appropriately
        }
    };

    useEffect(() => {
        fetchResumes();
    }, []);

    return (
        <div className="company-oa-wrapper">
            <div className="scroll-progress-container">
                <div 
                    className="scroll-progress-bar" 
                    style={{ transform: `translateX(${scrollProgress - 100}%)` }}
                >
                    <div className="progress-logo-container">
                        <SiCplusplus className="progress-logo" />
                    </div>
                </div>
            </div>
            {loading ? (
                <LoadingAnimation />
            ) : error ? (
                <div>Error: {error}</div>
            ) : (
                <main>
                    <SearchBar 
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearchChange}
                        searchType={searchType}
                        setSearchType={setSearchType}
                    />
                    {(globalSearchResults || filterCompanies().length > 0) && (
                        <div className="table-scroll-container">
                            <div className="table-inner-container">
                                <div className="company-table">
                                    <div className="sleek-table-header">
                                        <div>Company</div>
                                        <div>Date</div>
                                        <div className="sleek-count hide-mobile">Questions</div>
                                        <div className="hide-mobile">Solutions</div>
                                        <div className="desktop-only"></div>
                                    </div>
                                    <div className="sleek-table-body">
                                        {globalSearchResults !== null ? globalSearchResults.map((company) => (
                                            <div key={company.id} className="sleek-row">
                                                <div className="sleek-data" onClick={() => toggleCompany(company.id)}>
                                                    <div className="sleek-name">
                                                        <div className="sleek-icon-wrapper">
                                                            {React.createElement(getCompanyIcon(company.name), {
                                                                className: "sleek-icon"
                                                            })}
                                                        </div>
                                                        <span className="sleek-name-text">{company.name}</span>
                                                    </div>
                                                    <div className="sleek-date">
                                                        <span className="sleek-date-main">
                                                            {formatDate(company.date)}
                                                        </span>
                                                        <span className="sleek-date-ago desktop-only">
                                                            {getTimeAgo(new Date(company.date))}
                                                        </span>
                                                    </div>
                                                    <div className="sleek-count">
                                                        {company.questions ? company.questions.length : 0}
                                                    </div>
                                                    <div className="sleek-solutions">
                                                        <span className={`sleek-badge ${company.solutions_available ? 'available' : 'unavailable'}`}>
                                                            {company.solutions_available ? 'Available' : 'Unavailable'}
                                                        </span>
                                                    </div>
                                                    <div className="sleek-toggle desktop-only">
                                                        {expandedCompany === company.id ? <FaChevronUp /> : <FaChevronDown />}
                                                    </div>
                                                </div>
                                                {expandedCompany === company.id && (
                                                    <div className="sleek-questions-container">
                                                        {company.questions.length > 0 ? (
                                                            company.questions.map((question, index) => (
                                                                <div key={question.id} className="sleek-question-row"
                                                                     ref={el => questionRefs.current[question.id] = el}
                                                                     id={`question-${question.id}`}>
                                                                    <div className="sleek-question-content">
                                                                        <div className="sleek-question-info">
                                                                            <div className="sleek-question-number">{index + 1}</div>
                                                                            <Link 
                                                                                to={`/question/${question.id}`}
                                                                                state={{ questionData: {
                                                                                    ...question,
                                                                                    company_name: company.name
                                                                                }}}
                                                                                className="sleek-question-link"
                                                                            >
                                                                                
                                                                                <span className="question-text">{question.title}</span>
                                                                                
                                                                            </Link>
                                                                            <FaExternalLinkAlt className="link-icon" />
                                                                        </div>
                                                                        <div className="sleek-question-actions">
                                                                            {question.google_doc_link && (
                                                                                <a 
                                                                                    href={question.google_doc_link}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="sleek-original-btn"
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                >
                                                                                    <FaExternalLinkAlt /> Original Problem Statement
                                                                                </a>
                                                                            )}
                                                                            <a 
                                                                                href={`https://t.me/cphelper9?start=question_${encodeURIComponent(question.title)}_${encodeURIComponent(company.name)}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="sleek-solution-btn"
                                                                                onClick={(e) => e.stopPropagation()}
                                                                            >
                                                                                <FaLock /> Buy Solution
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {expandedProblem === question.id && (
                                                                        <>
                                                                            <div className="sleek-problem-statement">
                                                                                <div 
                                                                                    className="problem-content"
                                                                                    dangerouslySetInnerHTML={{ 
                                                                                        __html: DOMPurify.sanitize(formatProblemStatement(question.problem_statement))
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            
                                                                            <div 
                                                                                id={`code-section-${question.id}`} 
                                                                                className="code-editor-container"
                                                                            >
                                                                                <div className="code-editor-header">
                                                                                    <div className="code-editor-tabs">
                                                                                        <div className="code-tab active">
                                                                                            <SiCplusplus className="code-tab-icon" />
                                                                                            {`${question.title.toLowerCase()
                                                                                                .replace(/[^a-z0-9]+/g, '-')
                                                                                                .replace(/^-+|-+$/g, '')
                                                                                                .substring(0, 40)}.cpp`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <button 
                                                                                        className="code-unlock-btn"
                                                                                        onClick={(e) => handleSolutionClick(question.id, e)}
                                                                                    >
                                                                                        <FaLock /> Buy Solution
                                                                                    </button>
                                                                                </div>
                                                                                <div className="code-editor-content">
                                                                                    <pre className="code-content blurred">
                                                                                        <code>{SAMPLE_CPP_CODE}</code>
                                                                                    </pre>
                                                                                    <div className="code-overlay">
                                                                                        <FaLock className="overlay-lock-icon" />
                                                                                        <span>Get Premium Solution</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className="sleek-no-questions">No questions available for this company.</p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        )) : filterCompanies().map((company) => (
                                            <div key={company.id} className="sleek-row">
                                                <div className="sleek-data" onClick={() => toggleCompany(company.id)}>
                                                    <div className="sleek-name">
                                                        <div className="sleek-icon-wrapper">
                                                            {React.createElement(getCompanyIcon(company.name), {
                                                                className: "sleek-icon"
                                                            })}
                                                        </div>
                                                        <span className="sleek-name-text">{company.name}</span>
                                                    </div>
                                                    <div className="sleek-date">
                                                        <span className="sleek-date-main">
                                                            {formatDate(company.date)}
                                                        </span>
                                                        <span className="sleek-date-ago desktop-only">
                                                            {getTimeAgo(new Date(company.date))}
                                                        </span>
                                                    </div>
                                                    <div className="sleek-count">
                                                        {company.questions ? company.questions.length : 0}
                                                    </div>
                                                    <div className="sleek-solutions">
                                                        <span className={`sleek-badge ${company.solutions_available ? 'available' : 'unavailable'}`}>
                                                            {company.solutions_available ? 'Available' : 'Unavailable'}
                                                        </span>
                                                    </div>
                                                    <div className="sleek-toggle desktop-only">
                                                        {expandedCompany === company.id ? <FaChevronUp /> : <FaChevronDown />}
                                                    </div>
                                                </div>
                                                {expandedCompany === company.id && (
                                                    <div className="sleek-questions-container">
                                                        {company.questions.length > 0 ? (
                                                            company.questions.map((question, index) => (
                                                                <div key={question.id} className="sleek-question-row"
                                                                     ref={el => questionRefs.current[question.id] = el}
                                                                     id={`question-${question.id}`}>
                                                                    <div className="sleek-question-content">
                                                                        <div className="sleek-question-info">
                                                                            <div className="sleek-question-number">{index + 1}</div>
                                                                            <Link 
                                                                                to={`/question/${question.id}`}
                                                                                state={{ questionData: {
                                                                                    ...question,
                                                                                    company_name: company.name
                                                                                }}}
                                                                                className="sleek-question-link"
                                                                            >
                                                                                <FaExternalLinkAlt className="link-icon" />
                                                                                <span className="question-text">{question.title}</span>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="sleek-question-actions">
                                                                            {question.google_doc_link && (
                                                                                <a 
                                                                                    href={question.google_doc_link}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    className="sleek-original-btn"
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                >
                                                                                    <FaExternalLinkAlt /> Original Problem Statement
                                                                                </a>
                                                                            )}
                                                                            <a 
                                                                                href={`https://t.me/cphelper9?start=question_${encodeURIComponent(question.title)}_${encodeURIComponent(company.name)}`}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                className="sleek-solution-btn"
                                                                                onClick={(e) => e.stopPropagation()}
                                                                            >
                                                                                <FaLock /> Buy Solution
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                    {expandedProblem === question.id && (
                                                                        <>
                                                                            <div className="sleek-problem-statement">
                                                                                <div 
                                                                                    className="problem-content"
                                                                                    dangerouslySetInnerHTML={{ 
                                                                                        __html: DOMPurify.sanitize(formatProblemStatement(question.problem_statement))
                                                                                    }} 
                                                                                />
                                                                            </div>
                                                                            
                                                                            <div 
                                                                                id={`code-section-${question.id}`} 
                                                                                className="code-editor-container"
                                                                            >
                                                                                <div className="code-editor-header">
                                                                                    <div className="code-editor-tabs">
                                                                                        <div className="code-tab active">
                                                                                            <SiCplusplus className="code-tab-icon" />
                                                                                            {`${question.title.toLowerCase()
                                                                                                .replace(/[^a-z0-9]+/g, '-')
                                                                                                .replace(/^-+|-+$/g, '')
                                                                                                .substring(0, 40)}.cpp`}
                                                                                        </div>
                                                                                    </div>
                                                                                    <button 
                                                                                        className="code-unlock-btn"
                                                                                        onClick={(e) => handleSolutionClick(question.id, e)}
                                                                                    >
                                                                                        <FaLock /> Buy Solution
                                                                                    </button>
                                                                                </div>
                                                                                <div className="code-editor-content">
                                                                                    <pre className="code-content blurred">
                                                                                        <code>{SAMPLE_CPP_CODE}</code>
                                                                                    </pre>
                                                                                    <div className="code-overlay">
                                                                                        <FaLock className="overlay-lock-icon" />
                                                                                        <span>Get Premium Solution</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <p className="sleek-no-questions">No questions available for this company.</p>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showPaymentPopup && (
                        <PaymentPopup 
                            showPaymentPopup={showPaymentPopup}
                            setShowPaymentPopup={setShowPaymentPopup}
                            solutionEmail={solutionEmail}
                            setSolutionEmail={handleSolutionEmailChange}
                            handleSolutionSubmit={handleSolutionSubmit}
                            isSubmitting={isSubmitting}
                            selectedQuestion={selectedQuestion}
                        />
                    )}
                    {showSuccessPopup && (
                        <SuccessPopup />
                    )}
                    <Footer />
                </main>
            )}
        </div>
    );
};

export default CompanyOA;
