import React, { useState } from 'react';
import axios from 'axios';
import { FaCloudUploadAlt, FaCheck } from 'react-icons/fa';
import './ResumeReview.css';

const ResumeReview = () => {
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile?.type === 'application/pdf') {
      setFile(droppedFile);
      setUploadError('');
    } else {
      setUploadError('Only PDF files are allowed.');
    }
  };

  const handleFileInput = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile?.type === 'application/pdf') {
      setFile(selectedFile);
      setUploadError('');
    } else {
      setUploadError('Only PDF files are allowed.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !file) {
        setUploadError(!email ? 'Please enter your email address.' : 'Please upload your resume PDF.');
        return;
    }

    setIsLoading(true);
    setIsSubmitting(true);
    setUploadError('');
    setUploadSuccess(false);
    setAnalysis(null);

    const formData = new FormData();
    formData.append('email', email);
    formData.append('resume', file);
    formData.append('action', 'upload_resume_pdf');

    try {
        console.log('Sending resume for analysis...');
        const response = await axios.post('http://localhost:8888/saves/28th october/public/company_oa.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        console.log('API Response:', response.data);

        if (response.data.status === 'success' && response.data.data?.text) {
            setUploadSuccess(true);
            const parsedAnalysis = parseAnalysisText(response.data.data.text);
            console.log('Parsed Analysis:', parsedAnalysis);
            setAnalysis(parsedAnalysis);
            setFile(null);
            setEmail('');
        } else {
            throw new Error(response.data.message || 'Failed to analyze resume');
        }
    } catch (error) {
        console.error('Upload/Analysis error:', error);
        setUploadError(error.message || 'An error occurred while analyzing the resume');
    } finally {
        setIsLoading(false);
        setIsSubmitting(false);
    }
  };

  const parseAnalysisText = (text) => {
    console.log('Raw analysis text:', text);
    
    if (typeof text !== 'string') {
        console.error('Invalid analysis text format:', text);
        return {
            score: 0,
            strengths: ['Analysis failed'],
            improvements: ['Analysis failed'],
            formatting: 'Analysis failed'
        };
    }

    const sections = text.split('\n\n');
    let analysis = {
        score: 0,
        strengths: [],
        improvements: [],
        formatting: ''
    };

    sections.forEach(section => {
        const cleanSection = section.trim();
        
        if (cleanSection.toLowerCase().includes('overall score')) {
            const scoreMatch = cleanSection.match(/(\d+)/);
            analysis.score = scoreMatch ? parseInt(scoreMatch[1]) : 0;
        } 
        else if (cleanSection.toLowerCase().includes('strengths')) {
            analysis.strengths = cleanSection
                .split('\n')
                .filter(line => line.trim().startsWith('•'))
                .map(line => line.replace('•', '').trim());
        }
        else if (cleanSection.toLowerCase().includes('improvement')) {
            analysis.improvements = cleanSection
                .split('\n')
                .filter(line => line.trim().startsWith('•'))
                .map(line => line.replace('•', '').trim());
        }
        else if (cleanSection.toLowerCase().includes('formatting assessment')) {
            analysis.formatting = cleanSection
                .replace(/formatting assessment:?/i, '')
                .trim();
        }
    });

    console.log('Parsed analysis:', analysis);
    return analysis;
  };

  return (
    <div className="resume-review-container">
      <div className="resume-review-header">
        <h1>Professional Resume Review</h1>
        <p>Get your resume reviewed by industry experts</p>
      </div>

      <div className="resume-review-content">
        <form onSubmit={handleSubmit}>
          <div className="upload-section">
            <div 
              className={`upload-area ${isDragging ? 'dragging' : ''}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              <FaCloudUploadAlt className="upload-icon" />
              <h3>Drop your resume here</h3>
              <p>or</p>
              <label className="upload-button">
                Browse PDF
                <input
                  type="file"
                  accept=".pdf"
                  onChange={handleFileInput}
                  hidden
                />
              </label>
              {file && (
                <div className="file-info">
                  <FaCheck className="check-icon" />
                  <span>{file.name}</span>
                </div>
              )}
            </div>
            {uploadError && <p className="error-message">{uploadError}</p>}
            {uploadSuccess && <p className="success-message">Resume uploaded successfully!</p>}
          </div>

          <div className="email-input-container">
            <input
              type="email"
              className="email-input"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <button 
            type="submit" 
            className={`submit-button ${
              !file || !email || isSubmitting ? 'disabled' : ''
            }`}
            disabled={!file || !email || isSubmitting}
          >
            {isLoading ? (
              <span className="loading-spinner"></span>
            ) : isSubmitting ? (
              'Uploading...'
            ) : (
              'Submit for Review'
            )}
          </button>
        </form>
      </div>

      {analysis && (
        <div className="analysis-results">
          <h2>Resume Analysis</h2>
          <div className="score-section">
            <div className="score-circle">
              <span className="score-number">
                {analysis.score}
              </span>
              <span className="score-label">Score</span>
            </div>
          </div>
          
          <div className="analysis-section">
            <h3>Strengths</h3>
            <ul>
              {analysis.strengths.map((strength, index) => (
                <li key={index}>{strength}</li>
              ))}
            </ul>
          </div>
          
          <div className="analysis-section">
            <h3>Areas for Improvement</h3>
            <ul>
              {analysis.improvements.map((improvement, index) => (
                <li key={index}>{improvement}</li>
              ))}
            </ul>
          </div>
          
          <div className="analysis-section">
            <h3>Professional Formatting Assessment</h3>
            <p>{analysis.formatting}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeReview;