import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaFilePdf, FaDownload, FaEye } from 'react-icons/fa';
import './ResumeList.css';

const ResumeList = () => {
    const [resumes, setResumes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchResumes();
    }, []);

    const fetchResumes = async () => {
        try {
            console.log('Fetching resumes...');
            const response = await axios.get('http://localhost:8888/saves/28th october/public/company_oa.php', {
                params: { action: 'get_all_resumes' }
            });
            
            console.log('Response:', response.data);
            
            if (response.data.status === 'success') {
                setResumes(response.data.data);
            } else {
                setError(response.data.message || 'Failed to fetch resumes');
                console.error('API Error:', response.data);
            }
        } catch (error) {
            console.error('Error details:', error.response || error);
            setError(error.response?.data?.message || 'Error loading resumes');
        } finally {
            setLoading(false);
        }
    };

    const viewResume = (resumeId) => {
        window.open(`/company_oa.php?action=get_resume_pdf&id=${resumeId}`, '_blank');
    };

    const downloadResume = (resumeId, email) => {
        const link = document.createElement('a');
        link.href = `/company_oa.php?action=get_resume_pdf&id=${resumeId}`;
        link.download = `resume_${email}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    if (loading) return <div className="resume-loading">Loading resumes...</div>;
    if (error) return <div className="resume-error">Error: {error}</div>;
    if (!resumes?.length) return <div className="resume-empty">No resumes found</div>;

    return (
        <div className="resume-list-container">
            <h2>Uploaded Resumes ({resumes.length})</h2>
            <div className="resume-grid">
                {resumes.map((resume) => (
                    <div key={resume.id} className="resume-card">
                        <div className="resume-icon">
                            <FaFilePdf />
                        </div>
                        <div className="resume-details">
                            <p className="resume-email">{resume.email}</p>
                            <p className="resume-date">
                                {new Date(resume.upload_date).toLocaleDateString()}
                            </p>
                        </div>
                        <div className="resume-actions">
                            <button 
                                onClick={() => viewResume(resume.id)}
                                className="action-button view"
                            >
                                <FaEye /> View
                            </button>
                            <button 
                                onClick={() => downloadResume(resume.id, resume.email)}
                                className="action-button download"
                            >
                                <FaDownload /> Download
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ResumeList;